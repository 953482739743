<template>
  <el-main>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="用户昵称：">
        <el-input size="small" v-model="searchForm.nickname" placeholder="请输入用户昵称"></el-input>
      </el-form-item>
      <el-form-item label="订单编号：">
        <el-input size="small" v-model="searchForm.ordersn" placeholder="请输入用户手机号"></el-input>
      </el-form-item>
      <el-form-item label="结算状态：">
        <el-select size="small" v-model="searchForm.status" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="待入账" :value="1"></el-option>
          <el-option label="已入账" :value="2"></el-option>
          <el-option label="已取消" :value="3"></el-option>
          <el-option label="已结算" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="身份类型：">
        <el-select size="small" v-model="searchForm.identity_type" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="普通用户" :value="1"></el-option>
          <el-option label="推广员" :value="2"></el-option>
          <el-option label="区域管理员" :value="3"></el-option>
          <el-option label="专职回收员" :value="4"></el-option>
          <el-option label="兼职回收员" :value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="结算时间：">
        <el-date-picker v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="ordersn" label="订单编号" align="center"></el-table-column>
      <el-table-column label="下单用户信息" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.nickname" :avatar="row.orders_avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column label="推广员/区域管理员/回收员信息" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.name" :avatar="row.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column label="用户身份" align="center">
        <template v-slot="{ row }">
          {{ row.identity_type == 1 ? '普通用户' : row.identity_type == 2 ? '推广员' : row.identity_type == 3 ? '区域管理员' : row.identity_type == 4 ? '专职回收员' : '兼职回收员' }}
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="推广员/区域管理员/回收员电话" align="center"></el-table-column>
      <el-table-column prop="order_price" label="用户订单金额" align="center"></el-table-column>
      <el-table-column prop="recycle_price" label="回收站支付金额" align="center"></el-table-column>
      <el-table-column prop="amount" label="应得/奖励金额" align="center"></el-table-column>
      <el-table-column label="结算状态" align="center">
        <template v-slot="{ row }">
          {{ row.status == 1 ? '待入账' : row.status == 2 ? '已入账' : row.status == 3 ? '已取消' : '已结算' }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="结算时间" align="center"></el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import userInfo from '@/components/userInfo';
import Paging from '@/components/paging';
export default {
  components: {
    userInfo,
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        nickname: '',
        identity_type: '',
        ordersn: '',
        status: '',
        start_time: '',
        end_time: '',
      },
      list: [],
      total_number: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.searchForm.rows = 10;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        nickname: '',
        identity_type: '',
        ordersn: '',
        status: '',
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.O2O.finance.caseList, searchForm).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          list.map(item => {
            item.create_time = getDateformat(item.create_time);
            item.order_price = '￥' + item.order_price;
            item.amount = '￥' + item.amount;
            item.recycle_price = '￥' + item.recycle_price;
          });
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
